<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="1">
        <v-btn color="warning" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="11" class="text-h6 font-weight-regular">
        Inserimento Danni {{ tipoDannoCustom }}
      </v-col>
    </v-row>
    <v-expansion-panels v-model="panel" :disabled="disabled" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
          Gestione danni
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row v-if="tipoDanno === 'UTI' && anagraficaDanni && anagraficaDanni.length > 0">
            <v-col v-for="danno in anagraficaDanni" :key="danno.id">
              <v-checkbox :label="danno.descDanno" :value="danno" v-model="danniSelected">
              </v-checkbox>
            </v-col>
          </v-row>
          <DamageManagerRow v-else-if="tipoDanno === 'CARRO'" v-for="danno in anagraficaDanni" :key="danno.id" :danno="danno" />
          <v-row>
            <v-col cols="12" style="text-align: right">
              <v-btn color="success" class="pa-2 ma-2" @click="associaDanni()" medium>Salva</v-btn>
              <v-btn color="error" class="pa-2 ma-2" @click="$router.go(-1);" medium>Annulla</v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import ToastMixin from "../../mixins/ToastMixin";
import DamageManagerRow from "./DamageManagerRow.vue";

export default {
  name: "ConvoyAssociaDanni",
  tipoUti: null,
  components: { DamageManagerRow },
  mixins: [ToastMixin],
  props: ["carroConvoglioId", "utiCarroId", "tipoDanno"],
  data() {
    return {
      carroConvoglio: {},
      anagraficaDanni: [],
      uti: {},
      danniAssociati: [],
      danniSelected: [],
      panel: [0],
      disabled: false,
    };
  },
  created() {

  },
  async mounted() {
    this.anagraficaDanni = (await this.$api.get(
      this.$apiConfiguration.BASE_PATH +
      "danni/getByTipoDanno/" +
      this.tipoDanno
    )).map(el => {
      el.selected = false;
      el.note = '';
      return el;
    }); // prendo i tipi danno e li arricchisco con i campi selected e note

    if (this.tipoDanno === "CARRO") {
      this.carroConvoglio = await this.$api.get(
        this.$apiConfiguration.BASE_PATH + "carriConvoglio/" + this.carroConvoglioId
      );

      this.danniAssociati = await this.$api.get(
        this.$apiConfiguration.BASE_PATH + "logDanni/carroConvoglio/" + this.carroConvoglioId
      );

      this.anagraficaDanni.forEach( an => {
        const d = this.danniAssociati.find( da => da.carroDannoId === an.id);
        an.selected = !!d;
        an.note = !!d ? d.note : '';
      });
    }

    else if (this.tipoDanno === "UTI") {
      this.uti = await this.$api.get(
        this.$apiConfiguration.BASE_PATH + "utiCarro/getById/" + this.utiCarroId
      );

      this.danniAssociati = await this.$api.get(
        this.$apiConfiguration.BASE_PATH + "logDanni/findAllByVisitaUtiId/" + this.uti.visitaUtiId
      );

      this.anagraficaDanni.forEach((anagrafica) => {
        this.danniAssociati.forEach((dannoLog) => {
          if (anagrafica.id === dannoLog.utiDannoId) {
            this.danniSelected.push(anagrafica);
            return;
          }
        });
      });
    }
  },
  computed: {
    targaCarro() {
      if (this.carroConvoglio && this.carroConvoglio.visitaCarro) {
        return this.carroConvoglio.visitaCarro.targa;
      }
    },
    codiceUti() {
      if (this.uti.utiCodice) {
        return this.uti.utiCodice;
      }
    },
    tipoDannoCustom() {
      if (this.tipoDanno === "CARRO") {
        return "Carro " + this.targaCarro;
      }

      if (this.tipoDanno === "UTI") {
        return "Uti " + this.codiceUti;
      }
    },
  },
  methods: {
    associaDanni() {
      //alert(JSON.stringify(this.danniSelected));
      if (this.tipoDanno === "CARRO") {
        const associaDanniCarroPath =
          this.$apiConfiguration.BASE_PATH + "logDanni/createByCarroConvoglio";
        const reqCarroObj = {
          carroConvoglioId: this.carroConvoglio.id,
          listDanniCarro: this.anagraficaDanni.filter( d => d.selected ),
        };
        this.$api.post(associaDanniCarroPath, reqCarroObj).then(response => {
          if (response === "") {
            this.showSuccess("Salvataggio eseguito con successo");
          }
        });
      }

      if (this.tipoDanno === "UTI") {
        const associaDanniUtiPath =
          this.$apiConfiguration.BASE_PATH + "logDanni/createByUtiCarro";
        const reqUtiObj = {
          carroConvoglioId: this.carroConvoglioId,
          utiCarroId: this.uti.id,
          listDanniUti: this.danniSelected,
        };
        this.$api.post(associaDanniUtiPath, reqUtiObj).then(response => {
          if (response === "") {
            this.showSuccess("Salvataggio eseguito con successo");
          }
        });
      }
    },
    printData() {
      console.log(this.danniSelected);
    }
  },
};
</script>

<style scoped>
.uti-rob {
  background-image: repeating-linear-gradient(-45deg,
      #ffffff,
      #ffffff 5px,
      rgb(196, 196, 192) 5px,
      rgb(196, 196, 192) 10px) !important;
}
</style>
