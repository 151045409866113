<template>
  <v-row>
    <v-col>
      <v-checkbox 
        @change="printData()"
        :label="danno.descDanno"
        v-model="danno.selected" />
      <v-textarea 
        rows="1"
        @change="onTAChange()"
        v-if="danno.selected" 
        :label="tLabel('Danno \'') + danno.descDanno + tLabel('\' - Note')" 
        v-model="danno.note"/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DamageManagerRow",
  components: {},
  props: ["danno"],
  created() {},
  async mounted() {
    
  },
  computed: {},
  methods: {
    onTAChange() {
      console.log("---");
    },
    printData() {
      console.log(this.danno);
    }
  },
};
</script>

<style scoped>
.uti-rob {
  background-image: repeating-linear-gradient(
    -45deg,
    #ffffff,
    #ffffff 5px,
    rgb(196, 196, 192) 5px,
    rgb(196, 196, 192) 10px
  ) !important;
}
</style>
